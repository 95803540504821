import { createReducer, on } from '@ngrx/store';
import * as SupportTicketActions from './support-ticket.actions';

export interface SupportTicket {
  loading: boolean;
  loaded: boolean;
  validating: boolean;
  tickets: any[];
}

export const support_ticket_FeatureKey = 'support_ticket';
export const initialState: SupportTicket = {
  loading: false,
  loaded: false,
  validating: false,
  tickets: []
};

export const supportTicketReducer = createReducer(
  initialState,

  on(SupportTicketActions.getSupportTicket, state => ({
    ...state,
    loading: true
  })),

  on(SupportTicketActions.getSupportTicketSuccess, (state, action) => ({
    ...state,
    loading: false,
    loaded: true,
    tickets:  {
      ... state.tickets,
      [action.ticket_uuid] : action.data
    }
  })),

  on(SupportTicketActions.getSupportTicketFailure, (state, action) => ({
    ...state,
    loading: false,
    loaded: false,
    tickets:  {
      ... state.tickets,
      [action.ticket_uuid] : action.error

    }
  })),


  on(SupportTicketActions.validateSupportTicket, state => ({
    ...state,
    validating: true
  })),

  on(SupportTicketActions.validateSupportTicketSuccess, (state, action) => ({
    ...state,
    validating: false,
    tickets:  {
      ... state.tickets,
      [action.ticket_uuid] : false
    }
  })),

  on(SupportTicketActions.validateSupportTicketFailure, (state, action) => ({
    ...state,
    validating: false,
    tickets:  {
      ... state.tickets,
      [action.ticket_uuid] : action.error

    }
  })),
);
