import { createAction, props } from '@ngrx/store';

export const getSupportTicket = createAction('[support ticket] get ticket', props<{ticket_uuid: string}>());
export const getSupportTicketSuccess = createAction('[support ticket] get ticket success', props<{ data: any, ticket_uuid: string }>());
export const getSupportTicketFailure = createAction('[support ticket] get ticket failiure', props<{ error: Error, ticket_uuid: string }>());


export const validateSupportTicket = createAction('[support ticket] validate ticket', props<{ticket_uuid: string,data: any}>());
export const validateSupportTicketSuccess = createAction('[support ticket] validate ticket success', props<{ data: any, ticket_uuid: string }>());
export const validateSupportTicketFailure = createAction('[support ticket] validate ticket failiure', props<{ error: Error, ticket_uuid: string  }>());
